import React from 'react';
import Buttons from './buttons';
import Deck from './deck';
import styles from './styles.module.css';
import Bar from './bar';

export default function App() {
	return (
		<>
			<Bar />
			<main className="h-full min-h-screen bg-base-200 overflow-hidden">
				<div className={`flex fill center ${styles.container}`}>
					<Deck />
				</div>
			</main>
		</>
	);
}
