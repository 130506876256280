import { Spade, Heart, Diamond, Club } from 'lucide-react';

export default function Bar() {
	return (
		<div className="navbar bg-base-100">
			<div className="navbar-start">
				<div className="dropdown">
					<div
						tabIndex={0}
						role="button"
						className="btn btn-ghost lg:hidden"
					>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							className="h-5 w-5"
							fill="none"
							viewBox="0 0 24 24"
							stroke="currentColor"
						>
							<path
								strokeLinecap="round"
								strokeLinejoin="round"
								strokeWidth="2"
								d="M4 6h16M4 12h8m-8 6h16"
							/>
						</svg>
					</div>
					<ul className="menu menu-sm dropdown-content mt-3 z-[1] p-2 shadow bg-base-100 rounded-box w-36">
						<li>
							<a>
								<Spade />
								About
							</a>
						</li>
						<li>
							<a>
								<Heart />
								Contact
							</a>
						</li>
						<li>
							<a>
								<Club />
								Demos
							</a>
						</li>
						<li>
							<a>
								<Diamond />
								Donate
							</a>
						</li>
					</ul>
				</div>
				<a className="btn btn-ghost font-mono text-xl bg-base-100 items-center justify-center">
					BATISTA, F.S.
				</a>
			</div>
			<div className="navbar-center hidden lg:flex">
				<ul className="menu menu-horizontal px-1">
					<li>
						<a>
							<button className="h-10 w-32  flex border-1 justify-center items-center gap-1 rounded-lg  ">
								<Spade />
								About
							</button>
						</a>
					</li>
					<li>
						<a>
							<button className="h-10 w-32 flex border-1 justify-center items-center gap-1 rounded-lg ">
								<Heart color="red" />
								Contact
							</button>
						</a>
					</li>
					<li>
						<a>
							<button className="h-10 w-32 flex border-1 justify-center items-center gap-1 rounded-lg ">
								<Club />
								Demos
							</button>
						</a>
					</li>
					<li>
						<a>
							<button className="h-10 w-32 flex border-1 justify-center items-center gap-1 rounded-lg ">
								<Diamond color="red" />
								Donate
							</button>
						</a>
					</li>
					<li>
						<a
							href="https://www.buymeacoffee.com/nandofs"
							rel="noreferrer"
							target="_blank"
						>
							<img
								src="https://cdn.buymeacoffee.com/buttons/v2/default-yellow.png"
								alt="Buy Me A Coffee"
								className="h-10 w-32"
							/>
						</a>
					</li>
				</ul>
			</div>
			<div className="navbar-end">
				<label className="cursor-pointer grid place-items-center">
					<input
						type="checkbox"
						value="dark"
						className="toggle theme-controller bg-base-content row-start-1 col-start-1 col-span-2"
					/>
					<svg
						className="col-start-1 row-start-1 stroke-base-100 fill-base-100"
						xmlns="http://www.w3.org/2000/svg"
						width="14"
						height="14"
						viewBox="0 0 24 24"
						fill="none"
						stroke="currentColor"
						strokeWidth="2"
						strokeLinecap="round"
						strokeLinejoin="round"
					>
						<circle
							cx="12"
							cy="12"
							r="5"
						/>
						<path d="M12 1v2M12 21v2M4.2 4.2l1.4 1.4M18.4 18.4l1.4 1.4M1 12h2M21 12h2M4.2 19.8l1.4-1.4M18.4 5.6l1.4-1.4" />
					</svg>
					<svg
						className="col-start-2 row-start-1 stroke-base-100 fill-base-100"
						xmlns="http://www.w3.org/2000/svg"
						width="14"
						height="14"
						viewBox="0 0 24 24"
						fill="none"
						stroke="currentColor"
						strokeWidth="2"
						strokeLinecap="round"
						strokeLinejoin="round"
					>
						<path d="M21 12.79A9 9 0 1 1 11.21 3 7 7 0 0 0 21 12.79z"></path>
					</svg>
				</label>
			</div>
		</div>
	);
}
