import { animated, to as interpolate, useSprings } from '@react-spring/web';
import { useDrag } from '@use-gesture/react';

import React, { useState } from 'react';
import Axios from './assets/axios.svg';
import Express from './assets/express.svg';
import Git from './assets/git.svg';
import Github from './assets/github.svg';
import Next from './assets/nextdotjs.svg';
import Node from './assets/nodedotjs.svg';
import Prisma from './assets/prisma.svg';
import Reactjs from './assets/react.svg';
import Redis from './assets/redis.svg';
import Swagger from './assets/swagger.svg';
import Tailwind from './assets/tailwind.svg';
import Typescript from './assets/typescript.svg';
import Vite from './assets/vite.svg';
import styles from './styles.module.css';

const cards = [ Git, Swagger, Typescript, Redis, Axios, Prisma, Express, Github, Tailwind, Next, Vite, Reactjs, Node ];

const to = (i) => ({
  x: 0,
  y: i * -4,
  scale: 1,
  rot: -10 + Math.random() * 20,
  delay: i * 100,
})
const from = (_i) => ({ x: 0, rot: 0, scale: 1.5, y: -1000 })

const trans = (r, s) =>
  `perspective(1500px) rotateX(30deg) rotateY(${r / 10}deg) rotateZ(${r}deg) scale(${s})`


export default function Deck() {

  const [ gone ] = useState(() => new Set())
  const [ props, api ] = useSprings(cards.length, i => ({
    ...to(i),
    from: from(i),
  })) 
  const bind = useDrag(({ args: [ index ], active, movement: [ mx ], direction: [ xDir ], velocity: [ vx ] }) => {
    const trigger = vx > 0.2
    if (!active && trigger) gone.add(index) 
    api.start(i => {
      if (index !== i) return 
      const isGone = gone.has(index)
      const x = isGone ? (200 + window.innerWidth) * xDir : active ? mx : 0 
      const rot = mx / 100 + (isGone ? xDir * 10 * vx : 0)
      const scale = active ? 1.1 : 1 
      return {
        x,
        rot,
        scale,
        delay: undefined,
        config: { friction: 50, tension: active ? 800 : isGone ? 200 : 500 },
      }
    })
    if (!active && gone.size === cards.length)
      setTimeout(() => {
        gone.clear()
        api.start(i => to(i))
      }, 600)
  })
 
  return (
    <>
    <p className="text-white font-bold font-mono">FERNANDO</p>
      {props.map(({ x, y, rot, scale }, i) => (
        <animated.div className={styles.deck} key={i} style={{ x, y }}>
         
          <animated.div
            {...bind(i)}            
            style={{
              transform: interpolate([ rot, scale ], trans),
              backgroundImage: `url(${cards[ i ]})`,
            }}
          />
        </animated.div>
      ))}
    </>
  )
}